export const labels = {
  application: {
    buttons: {
      add: "Add an application",
    },
    fields: {
      name: {
        tooltipText:
          "Choose a descriptive application name. It should consist of 2-64 letters or numbers, spaces, underscores or dashes, uppercase and lowercase letters.",
        label: "Application name",
      },
      group: {
        label: "Group",
        tooltipText:
          "Groups are used to identifying the parties building applications and data sources." +
          " They contain the party configuration for Data Product signatures and will be used for access control by allowing you manage members.",
      },
      clientID: {
        label: "Client ID",
        tooltipText:
          "Unique identifier for the Application for the OpenID connect provider.",
      },
      clientSecrets: {
        label: "Client secret",
        tooltipText:
          "Client secrets are generated when creating an appliation and are used when the application talks with the OpenID connect provider.",
      },
      redirectURIs: {
        label: "Redirect URIs",
        tooltipText:
          "List of addresses that the OpenID Connect provider should allow returning to after authentication completes or fails",
      },
      logoutRedirectURIs: {
        label: "Logout redirect URIs",
        tooltipText:
          "List of addresses that the OpenID Connect provider should allow returning to after log out completes or fails",
      },
      website: {
        label: "Application's website URL",
        tooltipText: "Homepage for the application (if any).",
      },
      privacyPolicy: {
        label: "Application's privacy policy URL",
        tooltipText:
          "Link to the privacy policy that can be shared with the users of the Dataspace e.g. on the Consent Portal",
      },
    },
    infoBox: {
      noApp: "You have no applications",
      createSecret:
        "Client ID and secrets will be automatically generated after creation.",
    },
  },
  dataSource: {
    buttons: {
      add: "Create new data source",
    },
    fields: {
      description: {
        label: "Description",
        tooltipText: "Maximum length 1000 characters.",
      },
      dataProduct: {
        label: "Data Product",
        tooltipText: "The Data Product you want to implement with your data source.",
      },
      group: {
        tooltipText:
          "Groups are used to identifying the parties building applications and data sources." +
          " They contain the party configuration for Data Product signatures and will be used for access control by allowing you manage members.",
        label: "Group",
      },
      variant: {
        label: "Variant",
        tooltipText:
          "Used to identify individual data sources when a group has multiple implementations of the same data product inside a group.",
      },
      baseUrl: {
        label: "Base URL",
        tooltipText:
          "Will be added before the data product path when making a request to the data source.",
      },
      source: {
        label: "Source",
        tooltipText:
          "The unique identifier for a data product. The source name comes from the group name and the optional tag. ",
      },
      previewToken: {
        label: "Preview Token",
        tooltipText:
          "Add the token value in an X-Preview-Token HTTP header when making requests to the product gateway to test your data product before it is published.",
      },
    },
  },
  group: {
    buttons: {
      add: "Add a group",
    },
    fields: {
      name: {
        label: "Unique name for the group",
        tooltipText:
          "Names can contain lowercase letter a-z, numbers 0-9, and underscores (  _ ) and be 2-64 characters long, and they have to be unique within the Dataspace.",
      },
      partyConfigurationDomain: {
        label: "Party configuration domain",
        tooltipText:
          "Base domain where the party-configuration.json file can be located. Used for verifying the signatures made by the party e.g. for Data Product signatures and Consent Portal requests. More information available under the developer resources section.",
      },
      gdprContact: {
        label: "GDPR contact",
        tooltipText: "Contact email for GDPR related issues",
      },
      technicalContact: {
        label: "Technical contact",
        tooltipText: "Contact email for technical issues",
      },
    },
  },
  availableSources: {
    fields: {
      dataProduct: {
        name: "Data product",
      },
      dataSourceName: {
        name: "Data source name",
      },
      requestUrl: {
        name: "Request URL",
      },
      dataSourceIdentifier: {
        name: "Data source identifier",
      },
      gdprContact: {
        name: "GDPR contact",
      },
      partyConfiguration: {
        name: "Party configuration domain",
      },
    },
  },
  meta: {
    login: {
      title: "Login",
    },
    home: {
      title: "Home",
    },
    myApplications: {
      title: "My applications",
    },
    createApplications: {
      title: "Create an application",
    },
    editApplications: {
      title: "Edit an application",
    },
    createGroup: {
      title: "Create a new group",
    },
    editGroup: {
      title: "Edit a group",
    },
    myGroups: {
      title: "My groups",
    },
    createSources: {
      title: "Create a data source",
    },
    editSources: {
      title: "Edit a data source",
    },
    mySources: {
      title: "My data sources",
    },
    availableSources: {
      title: "Search available data sources",
    },
    terms: {
      title: "Terms of service",
    },
    resources: {
      title: "Developer resources",
    },
    accessControlKeys: {
      title: "Access control keys",
    },
  },
}
